<template>
    <div>
        <CCard v-if="showContent">
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <div class="info">
                    <p><b>Nombre: </b>{{ this.diseasesFields.name }}</p>
                    <p><b>Descripción de la enfermedad: </b>{{ this.diseasesFields.desc }}</p>
                </div>
                <div class="button-container">
                    <CButton class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                        Volver
                    </CButton>
                    <button v-if="!showForm && this.$store.state.isAdmin" class="btn btn-show-form" @click="showEditForm">
                        Editar enfermedad
                    </button>
                </div>
            </CCardBody>
        </CCard>
        <CCard v-if="showForm">
            <CCardHeader>
                {{ formTitle }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="diseasesFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { updateDisease, getDiseaseData, getDiseasesData } from '@/services/diseases';
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import { getFormEditDisease } from "@/utils/forms/diseases/form";

export default {
    name: "updateDisease",
    components: { AbstractForm },
    data(){
      return{
        id: null,
        title: `Información de la enfermedad`,
        formTitle: "Editar una enfermedad",
        diseases: [],
        showForm: false,
        showContent: true,
        diseasesFields: {
            name: null,
            desc: null
        },
        formContent: getFormEditDisease()
      }
    },
    created() {
        this.id = this.$route.params.id;
        if(this.id){
            this.getDisease()
            this.getDiseases();
        } 
    },
    methods: {
        onEmitMethods(event, dataDisease) {
            switch (event) {
                case 'edit': this.editDisease(dataDisease); break;
                case 'cancel': this.restoreContent(); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        /**
         * Función para obtener los datos del psicólogo en cuestión
        */
        getDisease() {
            if (!this.id) return;
            let data = getDiseaseData(this.id);
            data.catch((error) => {
                if (error.response && error.response.status === 404){
                    this.$router.push('/404');
                    return
                }
                this.$store.state.errorAlert = { status: true, msg: error.toString() 
            };})
            .then((result) => {
                if (localStorage.token == undefined) return;
                this.diseasesFields.name = result.name;
                this.diseasesFields.desc = result.desc;
            })
        },
       /**
       * Función en la que se obtienen las enfermedades
       * 
       * @returns {object[]} Las enfermedades obtenidas.
       */
       getDiseases() {
            getDiseasesData()
            .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
            .then((result) => {
                this.diseases = result.filter(disease => disease.id !== Number(this.id));
            });
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
         * Método para validar los inputs del formulario
         */
        checkInputs(dataDisease) {
            const specialCharsDescRegex = /^[a-zA-Z0-9,.!¡¿?()\sáéíóúÁÉÍÓÚüÜñÑ]+$/;
            const validationRules = [
                { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales.', regex: specialCharsDescRegex },
                { name: 'Descripción', field: 'desc', message: 'Introduzca una descripción (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Descripción', field: 'desc', message: 'La descripción no debe contener caracteres especiales.', regex: specialCharsDescRegex },
            ];

            const existingDisease = this.diseases.find(disease => {
                return disease.name.toLowerCase() === dataDisease.name.toLowerCase()
            });
            
            if (existingDisease) {
                this.showAlertError(`El nombre "${dataDisease.name}" ya existe en las enfermedades.`);
                return false;
            }
            
            for (const rule of validationRules) {
                const value = dataDisease[rule.field];
                if (value === undefined || value === null) {
                    this.showAlertError(`El campo ${rule.name} está vacío`);
                    return false;
                }
                if(rule.maxLength && value.length > rule.maxLength){
                    this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                    return false;
                }
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataDisease[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para poder editar una nueva enfermedad
         */
        editDisease(dataDisease) {
            if (this.checkInputs(dataDisease) == false) return;
            updateDisease(dataDisease, this.id)
                .then(() => {
                    this.$router.push("/diseases");
                }).catch((error) => {
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Función para mostrar el alert durante 10 segundos.
         */
        showFailureParameters() {
            this.contAlertParams = 10;
        },
        /**
         * Función para mostrar el formulario para editar la enfermedad
         */
         showEditForm(){
            this.showForm = true;
        },
        /**
         * Función para restaurar el contenido
         */
         restoreContent(){
            this.showContent = true;
            this.showForm = false;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.push("/diseases");
        },
    },
};
</script>